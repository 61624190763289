<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <template>
    <v-dialog
    v-model="run"
    max-width="450"
    transition="dialog-top-transition"
    >
    <v-card>
      <v-img
        :src="dialogBG"
        height="200px"
      ></v-img>
      <v-card-title>
        <span class="text-h4">Task Submitted Response</span>
      </v-card-title>
      <v-card-text>
        {{ diaLogMsg }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          text
          @click="run = false"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
    <v-dialog
    v-model="uploader"
    max-width="450"
    persistent
    transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title class="test-h4">
          Upload KG Files
        </v-card-title>
        <v-alert
        dense
        v-model="uploadAlertStatus"
        dismissible
        text
        tile
        type="error"
        >
          {{ uploadAlertMsg }}
        </v-alert>
        <v-list-item
        v-show="!showDefaultOption"
        v-for="(item, index) in fileList"
        :key="index"
        >
            <v-file-input
            show-size
            counter
            v-model="item.fileInfo"
            :label="item.fileName"
            :error="item.error"
            :error-messages="item.errorMsg"
            @change="validate(item)"
            >
            </v-file-input>
        </v-list-item>
        <v-container
        fluid
        v-show="showDefaultOption"
        >
        <v-row align="center">
          <v-col cols="8">
            <v-select
            v-model="uploadOptionSelected"
            :items="uploadOptions"
            item-text="fullName"
            item-value="fullName"
            return-object
            >
              Default KG Pairs
            </v-select>
          </v-col>
          <v-col
          cols="4"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="blue darken-1"
              small
              @click="downloadDefaultFiles"
              :disabled="downloadBtnDisabled"
            >
              Download
            </v-btn>
          </v-col>
        </v-row>
        </v-container>
        <v-progress-linear
        v-model="uploadProgress"
        color="primary"
        :active="progressActive"
        height="15"
        :indeterminate="indeterminate"
        dark
        >
        {{ uploadProgress.toString() + '%'}}
        </v-progress-linear>
        <v-progress-linear
        v-model="downloadProgress"
        color="info"
        :active="progressDownloadActive"
        height="15"
        :indeterminate="downloadIndeterminate"
        dark
        >
        {{ downloadProgress.toString() + '%'}}
        </v-progress-linear>
        <v-card-actions>
          <v-btn
           text
           color="blue darken-1"
           small
           @click="showDefaultOption = !showDefaultOption"
           :disabled="uploadBtnDisabled"
          >
            {{ uploadSwitcherText }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
           color="green darken-1"
           text
           small
           @click="uploadFiles"
           :disabled="uploadBtnDisabled"
          >
            Upload
          </v-btn>
          <v-btn
           color="grey"
           text
           small
           @click="uploader = false"
           :disabled="uploadBtnDisabled"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
    v-model="uploadCompleted"
    max-width="450"
    transition="dialog-top-transition"
    >
    <v-card>
      <v-img
        :src="uploadBG"
        height="200px"
      ></v-img>
      <v-card-title>
        <span class="text-h4">File Uploaded Response</span>
      </v-card-title>
      <v-card-text>
        {{ uploadedMsg }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          text
          @click="uploadCompleted = false"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
    </template>
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>
    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />
    <v-spacer />
    <v-toolbar-title
      class="hidden-sm-and-down"
      id="welcome"
      v-text="welcome"
    />
    <v-btn
      class="mr-4"
      elevation="2"
      small
      color="primary"
      @click="uploader = true"
    >
    Upload
    </v-btn>
    <v-btn
      class="mr-4"
      elevation="2"
      small
      color="secondary"
      @click="runTask"
    >
    Run
    </v-btn>
    <v-btn
      elevation="2"
      small
      color="info"
      @click="downloadMapping"
    >
    Save
    </v-btn>
    <v-menu
    bottom
    left
    >
      <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        v-bind="attrs"
        v-on="on"
        small
      >
        <v-icon>mdi-account</v-icon>
      </v-btn>
      </template>
     <v-list>
        <v-list-item
        v-for="(item, index) in userMenus"
        :key="index"
        @click="signOut"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  // Components
  // import { VHover, VListItem } from 'vuetify/lib'
  import api from '@/utils/api.js'
  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'PRASECoreAppBar',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      dialogBG: require('@/assets/images/dialog_bg.jpg'),
      uploadBG: require('@/assets/images/upload_bg.jpg'),
      userId: null,
      userMenus: [
        {
          title: 'Sign Out',
        },
      ],
      run: false,
      uploader: false,
      diaLogMsg: '',
      uploadProgress: 0,
      downloadProgress: 0,
      progressActive: false,
      progressDownloadActive: false,
      diaLogSuccessMsg: 'You have successfully submitted a new task. Please wait for the task to complete. You can also visit the Log page to track the execution of this task.',
      fileList: [
        {
          fileName: 'KG1 Relation Triple File',
          disable: false,
          fileTag: 'sourceKGRelTriples',
          fileInfo: undefined,
          error: false,
          errorMsg: [],
        },
        {
          fileName: 'KG1 Attribute Triple File',
          disable: false,
          fileTag: 'sourceKGAttrTriples',
          fileInfo: undefined,
          error: false,
          errorMsg: [],
        },
        {
          fileName: 'KG2 Relation Triple File',
          disable: false,
          fileTag: 'targetKGRelTriples',
          fileInfo: undefined,
          error: false,
          errorMsg: [],
        },
        {
          fileName: 'KG2 Attribute Triple File',
          disable: false,
          fileTag: 'targetKGAttrTriples',
          fileInfo: undefined,
          error: false,
          errorMsg: [],
        },
      ],
      uploadAlertStatus: false,
      uploadAlertMsg: 'Please Select a KG Pair Option.',
      uploadCompleted: false,
      uploadedMsg: '',
      uploadBtnDisabled: false,
      downloadBtnDisabled: false,
      indeterminate: false,
      downloadIndeterminate: false,
      showDefaultOption: true,
      uploadSwitcherText: 'Using Custom KGs',
      uploadOptions: [],
      uploadOptionSelected: {},
      defaultFileBaseURL: 'http://8.130.167.82:8080/default/download/',
      welcome: '',
      taskInfo: null,
    }),
    created () {
      this.userId = localStorage.getItem('taskId')
      if (this.userId === null) {
        this.$router.push('/')
      }
    },
    computed: {
      ...mapState(['drawer']),
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      signOut () {
        localStorage.clear()
        this.$router.push('/')
      },
      downloadMapping () {
        api.downloadMappingFile(this.userId).then((res) => {
          if (res.status === 200) {
            var fileURL = window.URL.createObjectURL(new Blob([res.data], { type: 'fileType' }))
            var link = document.createElement('a')
            link.style.display = 'none'
            link.href = fileURL
            link.setAttribute('download', this.userId + '.txt')
            document.body.appendChild(link)
            link.click()
          }
        })
      },
      downloadDefaultFiles () {
        if (this.uploadOptionSelected && typeof (this.uploadOptionSelected.id) !== 'undefined') {
          const id = this.uploadOptionSelected.id
          var config = {
            onDownloadProgress: progressEvent => {
              var complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
              this.downloadProgress = complete
            },
            timeout: 200000,
          }
          this.progressDownloadActive = true
          this.downloadBtnDisabled = true
          api.downloadDefaultFiles(id, config).then((res) => {
            if (res.status === 200) {
              var fileURL = window.URL.createObjectURL(new Blob([res.data], { type: 'fileType' }))
              var link = document.createElement('a')
              link.style.display = 'none'
              link.href = fileURL
              link.setAttribute('download', this.uploadOptionSelected.fullName + '.zip')
              document.body.appendChild(link)
              link.click()
            }
            this.progressDownloadActive = false
            this.downloadBtnDisabled = false
          })
          // const downloadURL = this.defaultFileBaseURL + id
          // var link = document.createElement('a')
          // link.style.display = 'none'
          // link.href = downloadURL
          // document.body.appendChild(link)
          // link.click()
        }
      },
      getTaskInfo () {
        api.getTaskInfo(this.userId).then((res) => {
          if (res.status === 200) {
            if (res.data.success) {
              this.taskInfo = res.data.data
            }
          }
        })
      },
      getUploadFileOptions () {
        api.getDefaultFileOptions().then((res) => {
          if (res.status === 200) {
            this.uploadOptions = res.data.data
          }
        })
      },
      runTask () {
        api.runTask(this.userId).then((res) => {
          if (res.status === 200) {
            if (res.data.success) {
              this.diaLogMsg = this.diaLogSuccessMsg
            } else {
              this.diaLogMsg = res.data.message
            }
          } else {
            this.diaLogMsg = 'Fail to get response from server.'
          }
          this.run = true
        })
      },
      validate (item) {
        item.errorMsg = []
        if (item.fileInfo === null || typeof (item.fileInfo) === 'undefined') {
          item.errorMsg.push('File is missing.')
          item.error = true
        } else {
          if (item.fileInfo.size > 1024 * 1024 * 20) {
            item.errorMsg.push('File size should be less than 20MB.')
            item.error = true
          } else {
            item.error = false
          }
        }
      },
      checkFiles () {
        var success = true
        for (let i = 0; i < this.fileList.length; ++i) {
          var item = this.fileList[i]
          this.validate(item)
          success = success && !item.error
        }
        return success
      },
      uploadFiles () {
        if (this.showDefaultOption) {
          if (this.uploadOptionSelected && typeof (this.uploadOptionSelected.id) !== 'undefined') {
            this.uploadAlertStatus = false
            const id = this.uploadOptionSelected.id
            const name = this.uploadOptionSelected.fullName
            this.uploadBtnDisabled = true
            api.setDefaultFiles(this.userId, id).then((res) => {
              this.uploader = false
              if (res.status === 200) {
                if (res.data.success) {
                  this.uploadedMsg = 'You have successfully uploaded default KG files (' + name + '). You can now click the RUN button to perform KG alignment.'
                } else {
                  this.uploadedMsg = 'ERROR: ' + res.data.message
                }
              } else {
                this.uploadedMsg = 'ERROR: Fail to get response from server.'
              }
              this.uploadBtnDisabled = false
              this.uploadCompleted = true
            })
          } else {
            this.uploadAlertStatus = true
          }
          return
        }
        var success = this.checkFiles()
        if (!success) {
          this.uploadAlertStatus = true
          return
        } else {
          this.uploadAlertStatus = false
        }
        var formData = new window.FormData()
        formData.append('taskId', this.userId)
        for (let i = 0; i < this.fileList.length; ++i) {
          var fileItem = this.fileList[i]
          formData.append(fileItem.fileTag, fileItem.fileInfo)
        }
        var config = {
          onUploadProgress: progressEvent => {
            var complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
            this.uploadProgress = complete
          },
          timeout: 200000,
        }
        this.uploadBtnDisabled = true
        api.uploadKGFiles(formData, config).then((res) => {
          this.progressActive = false
          this.uploader = false
          if (res.status === 200) {
            if (res.data.success) {
              this.uploadedMsg = 'You have successfully uploaded the KG files. You can now click the RUN button to perform KG alignment.'
            } else {
              this.uploadedMsg = 'ERROR: ' + res.data.message
            }
          } else {
            this.uploadedMsg = 'ERROR: Fail to get response from server.'
          }
          this.uploadBtnDisabled = false
          this.uploadCompleted = true
        })
      },
      progressHandler () {
        if (this.uploadProgress <= 0 || this.uploadProgress >= 100) {
          this.indeterminate = true
        } else {
          this.indeterminate = false
          this.progressActive = true
        }
      },
      downloadProgressHandler () {
        if (this.downloadProgress <= 0 || this.downloadProgress >= 100) {
          this.downloadIndeterminate = true
        } else {
          this.downloadIndeterminate = false
          this.progressDownloadActive = true
        }
      },
      uploadSwitchHandler () {
        if (this.showDefaultOption) {
          this.uploadSwitcherText = 'Using Custom KGs'
          this.uploadAlertMsg = 'Please Select a KG Pair Option.'
        } else {
          this.uploadAlertMsg = 'Please Fix the Errors'
          this.uploadSwitcherText = 'Using Default KGs'
        }
      },
      taskInfoHandler () {
        if (this.taskInfo) {
          this.welcome = 'Welcome, ' + this.taskInfo.userName + '!'
        } else {
          this.welcome = ''
        }
      },
    },
    mounted () {
      this.getUploadFileOptions()
      this.getTaskInfo()
    },
    watch: {
      uploadProgress: 'progressHandler',
      downloadProgress: 'downloadProgressHandler',
      showDefaultOption: 'uploadSwitchHandler',
      taskInfo: 'taskInfoHandler',
    },
  }
</script>
<style lang="css" scoped>
  #welcome {
    margin-right: 10px;
    color: '#082543';
    font-weight: normal;
  }
</style>
